<template>
    <v-card>
        <!--search input-->
        <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                          hide-details></v-text-field>
        </v-card-title>

        <v-data-table :headers="headers" :items="videos" :search="search" disable-pagination hide-default-footer>

            <!--update button-->
            <template v-slot:[`item.update`]="{ item }">
                <router-link :to="{ name: 'Video', params: { id: item.id }}">
                    <v-btn class="mx-2" icon color="#fbc02d">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </router-link>
            </template>

            <!--delete button-->
            <template v-slot:[`item.delete`]="{ item }">
                <v-btn @click="emitDeleteRequest(item.id)" class="mx-2" icon color="#f44336">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>

        </v-data-table>
    </v-card>
</template>

<script>

export default {
    name: "VideoTable",
    props: {
        videos: {
            required: true,
            type: Array
        }
    },
    data: () => ({
        search: '',
        headers: [
            {text: 'Id', value: 'id'},
            {text: 'Title', value: 'title'},
            {text: 'Playlist id', value: 'playlist.id', sortable: true},
            {text: 'Playlist title', value: 'playlist.titleVO', sortable: true},
            {text: '', value: 'update', sortable: false},
            {text: '', value: 'delete', sortable: false}
        ]
    }),
    methods: {
        /**
         * delete a video
         * @param id
         * @returns {Promise<void>}
         */
        async emitDeleteRequest(id) {
            this.$emit('showDeleteConfirmation', id);
        }
    }
}
</script>

<style scoped>

</style>