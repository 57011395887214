<template>
    <v-row justify="space-around">
        <v-col cols="auto">
            <v-dialog v-model="dialog" max-width="400">
                <v-card>

                    <v-toolbar color="#805acb" dark>{{ title }}</v-toolbar>

                    <v-spacer></v-spacer>
                    <v-card-text>
                        <div class="text-h6 pa-12">{{ text }}</div>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#805acb" text @click="cancel">{{ rejectButtonText }}</v-btn>
                        <v-btn color="#805acb" text @click="confirm">{{ validateButtonText }}</v-btn>
                    </v-card-actions>

                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>

export default {
    name: "ConfirmDialog",
    props: {
        title: {
            required: true,
            type: String
        },
        validateButtonText: {
            type: String,
            default: "Yes"
        },
        rejectButtonText: {
            type: String,
            default: "No"
        },
    },
    data: () => ({
        dialog: false,
        id: null,
        text: ""
    }),
    methods: {
        /**
         * Set up confirmDialog data
         *
         * @param user
         * @param text
         */
        setData({id, text}) {
            this.id = id;
            this.text = text;
            this.dialog = true;
        },
        /**
         * Cancel dialog
         */
        cancel() {
            this.user = null;
            this.dialog = false;
        },
        /**
         * Confirm dialog
         */
        confirm() {
            this.dialog = false;
            this.$emit('confirm', this.id);
        }
    }
}
</script>