<template>
    <v-container fluid style="padding-top: 70px; justify-content: center">
        <alert ref="alertComponent"></alert>
        <confirm-dialog ref="confirmDialog" title="Delete video" @confirm="deleteVideo($event)"></confirm-dialog>

        <video-table :videos="videos"></video-table>
    </v-container>
</template>

<script>
import Alert from "@/components/Alert.component";
import ConfirmDialog from "@/components/ConfirmDialog.component";
import VideoTable from "@/components/VideoTable.component";
import Services from "@/services/api";

export default {
    name: "Videos",
    components: {Alert, ConfirmDialog, VideoTable},
    data: () => ({
        videos: []
    }),
    async mounted() {
        await this.loadVideos();
    },
    methods: {
        async loadVideos() {
            try {
                this.videos = await Services.Videos.getAllVideos();
            } catch (e) {
                this.$refs.alertComponent.showErrorMessage(e.message);
            }
        },
        showDeleteConfirmation(id) {
            let videoToDelete = this.videos.find(m => m.id === id);
            let dialogText = `Do you really want to delete the video '${videoToDelete.titleVO}' ?`;
            this.$refs.confirmDialog.setData({id, text: dialogText});
        },
        /**
         * delete a video
         * @param id
         * @returns {Promise<void>}
         */
        async deleteVideo(id) {
            try {
                await Services.Videos.deleteVideo(id);
                let index = this.videos.findIndex(m => m.id === id);
                this.videos.splice(index, 1);
                this.$refs.alertComponent.showSuccessMessage("Video successfully deleted.");
            } catch (e) {
                this.$refs.alertComponent.showErrorMessage(e.message);
            }
        }
    }
}
</script>
